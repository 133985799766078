<template>
    <el-container :style="{ height: '100%' }">

        <!-- Header -->
        <el-header :style="{ padding: '0px', zIndex: 2 }">

            <uw-menu>

                <!-- Left -->
                <template slot="left">

                    <!-- Logo -->
                    <uw-menu-item path="/"><b :style="{ color: '#0027ff' }">WANER</b></uw-menu-item>

                    <!-- Nav -->
                    <uw-menu-sub
                        v-for="v in router_cascader"
                        :key="v.id"
                        :grid-column="Object.keys(v.children).length"
                        :grid-gutter="50"
                        :title="v.name"
                        :show-icon="v.visible_icon"
                        :show-title="v.visible_name"
                        bold
                        >
                        <!-- Nav Group -->
                        <uw-menu-item-group v-for="(m, n) in v.children" :key="n" :title="n">

                            <!-- Nav Group Button -->
                            <uw-menu-item v-for="e in m" :key="e.id" :path="e.path" :title="e.name" />

                        </uw-menu-item-group>

                    </uw-menu-sub>

                </template>

                <!-- Center -->
                <template slot="center">
                    <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
                    <!-- 全局检索 -->
                </template>

                <!-- Right -->
                <template slot="right">
                    <uw-menu-item :show-title="false" path="http://172.168.88.152" title="知识库" target="_blank" icon="el-icon-collection"></uw-menu-item>
                </template>
            </uw-menu>

        </el-header>

        <el-container :style="{ position: 'relative' }" v-if="this.$store.state.user">
            <router-view />
        </el-container>

    </el-container>
</template>

<script>
import '@/assets/css/waner.less'

export default {
    data() {
        return {
            activeName: null,
            menuPath: null,
            menuSubPath: null
        }
    },

    computed: {
        router_cascader() {
            return this.$store.state.user?.router_cascader
        }
    },

    /**
    |--------------------------------------------------
    | 路由更新前 访问权限校验
    |--------------------------------------------------
    |
    */

    beforeRouteUpdate(to, from, next) {
        const routerViews = from.matched[0].instances.default.$store.state.routerViews
        !this.ViewAuth(to.path, routerViews) ? next(this.SearchRouterDefault(routerViews)) : next()
    },

    /**
    |--------------------------------------------------
    | 页面加载前
    |--------------------------------------------------
    |
    */

    created () {

        // 获取用户信息
        this.$http.post('/9api/my/info', { append: ['router_views', 'router_cascader'] }).then(rps => {
            this.$store.commit('setUser', rps.data)
            this.menuPath = rps.data.router_cascader[0].path

            // 获取用户当前角色的路由访问权限
            this.$http.post('/9api/router-views/search-ids', { ids: [].concat(...rps.data.router_views.map((item) => item.router_views_id )) }).then(rps => {
                this.$store.commit('setRouterViews', rps.data)

                // 前端路由 访问权限校验
                if (!this.ViewAuth(this.$route.path, rps.data)) {
                    this.$router.push(this.SearchRouterDefault(rps.data))
                }
            })
        })
    },

    methods: {

        /**
        |--------------------------------------------------
        | 前端路由 访问权限校验
        |--------------------------------------------------
        |
        */

        ViewAuth (path, views) {
            if ( views.findIndex((item => item.path == path)) > -1 ) {
                return true
            } else {
                this.$notify.error({
                    title: '权限问题',
                    message: '您没有权限访问此页面，请联系管理员！'
                })

                return false
            }
        },

        /**
        |--------------------------------------------------
        | 前端路由 查询最优默认路由
        |--------------------------------------------------
        |
        */

        SearchRouterDefault(views) {
            
            let child = views.filter((item) => { return item.parent == views[0].id && item.path })

            if (child.length > 0) {
                return  this.SearchRouterDefault(child)
            } else {
                return views[0].path
            }
        }
    }
}

</script>